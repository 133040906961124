@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400");
.block-title {
  padding-top: 100px;
  width: 100%;
  text-align: center; }
  .block-title .accent-container {
    display: inline-block;
    position: relative;
    margin: auto; }
    .block-title .accent-container h1 {
      display: inline-block; }
    .block-title .accent-container .accent {
      background-color: #F2C200;
      width: 50px;
      height: 1px;
      position: absolute; }
      .block-title .accent-container .accent.left {
        left: -60px;
        top: 50%; }
      .block-title .accent-container .accent.right {
        right: -60px;
        top: 50%; }

.header {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 600px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(20px);
  color: white;
  z-index: 9999;
  opacity: 0;
  transition: all 250ms; }
  .header #brand {
    position: absolute;
    padding-left: 20px;
    font-size: 2em;
    margin: 0;
    line-height: 80px;
    display: none; }
    @media (min-width: 992px) {
      .header #brand {
        display: block; } }
  .header.visible {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
    opacity: 1; }

.hero {
  background-color: #222;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, #101010 100%), url("../assets/images/bg2.jpg") no-repeat;
  background-position: center;
  height: 100vh;
  color: white;
  text-align: center; }
  .hero .text-box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .hero .text-box h1 {
      font-size: 40px;
      font-weight: 300;
      border-bottom: 1px solid #F2C200;
      padding-bottom: 10px;
      margin-bottom: 15px; }
    .hero .text-box h2 {
      font-size: 25px;
      font-weight: 300;
      margin-top: 0px;
      margin-bottom: 30px; }
  .hero .chevron {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 60px;
    height: 60px;
    background-image: url("../assets/images/chevron-down.svg");
    background-size: 40px 40px;
    background-position: center;
    background-repeat: no-repeat;
    animation: chevron-animate infinite alternate 1s cubic-bezier(0.77, 0, 0.175, 1); }

@keyframes chevron-animate {
  from {
    transform: translate(-50%, 0px); }
  to {
    transform: translate(-50%, 40px); } }

.nav-bar {
  height: 100%; }
  .nav-bar .entry-container {
    width: 30%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; }
    .nav-bar .entry-container .entry {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      padding: 10px 10px;
      margin: 0px 5px;
      border-radius: 5px;
      transition: background-color 250ms, color 250ms; }
      @media (min-width: 768px) {
        .nav-bar .entry-container .entry {
          font-size: 16px;
          padding: 10px 30px; } }
      .nav-bar .entry-container .entry:hover, .nav-bar .entry-container .entry.active {
        background-color: rgba(155, 155, 155, 0.5);
        color: #F2C200; }

.skill-item {
  width: calc(50% - 2px);
  height: 160px;
  text-align: center; }
  .skill-item img {
    width: 75px;
    height: 75px; }
    @media (min-width: 1350px) {
      .skill-item img {
        width: 100px;
        height: 100px; } }
  .skill-item .label {
    margin-top: 5px;
    text-align: center;
    font-size: 1em; }
    @media (min-width: 1350px) {
      .skill-item .label {
        font-size: 1.2em; } }

.portfolio-item .skill-item {
  width: 75px;
  height: 75px; }
  .portfolio-item .skill-item img {
    width: 50px;
    height: 50px; }

.portfolio-item .label {
  font-size: 0.75em; }

.projects {
  width: calc(100% - 40px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .projects {
      width: 70%;
      flex-direction: row; } }
  .projects .portfolio-item {
    width: 100%;
    height: 300px;
    margin: auto;
    margin-bottom: 50px;
    position: relative;
    cursor: pointer;
    border: 1px solid white;
    transition: border 250ms;
    color: white;
    overflow: hidden; }
    .projects .portfolio-item:hover {
      border: 1px solid #F2C200; }
    @media (min-width: 992px) {
      .projects .portfolio-item {
        border: 1px solid #101010;
        width: 45%; }
        .projects .portfolio-item:hover .text {
          transform: translateY(0);
          border-top: 1px solid #F2C200;
          border-right: 1px solid #F2C200; }
        .projects .portfolio-item:hover .preview {
          background-color: #F2C200; } }
    .projects .portfolio-item .preview {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/bg2.jpg");
      background-color: transparent;
      background-blend-mode: multiply;
      background-size: cover;
      background-position: center;
      z-index: 1;
      transition: all 150ms; }
      .projects .portfolio-item .preview.crapmap {
        background-position: center -35px; }
    .projects .portfolio-item .text {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 10;
      padding: 20px;
      background-color: rgba(13, 13, 13, 0.95);
      box-sizing: border-box;
      backdrop-filter: blur(20px); }
      .projects .portfolio-item .text h2 {
        margin-top: 0px; }
      @media (min-width: 992px) {
        .projects .portfolio-item .text {
          background-color: #101010;
          width: 80%;
          border-top: 1px solid white;
          border-right: 1px solid white;
          transform: translateY(95px);
          transition: transform 250ms; } }
    .projects .portfolio-item .skills-list {
      display: flex;
      flex-direction: row; }
    .projects .portfolio-item h2 {
      font-weight: 400;
      margin-bottom: 10px; }
    .projects .portfolio-item h3 {
      font-weight: 400;
      margin-top: 0px;
      color: #F2C200; }

.contact .social-icon {
  width: 75px;
  height: 75px;
  cursor: pointer;
  display: block; }
  .contact .social-icon rect {
    fill: #FFFFFF;
    transition: fill 250ms; }
  .contact .social-icon:hover rect {
    fill: #F2C200; }

body {
  margin: 0; }

.universe {
  font-family: 'Lato';
  letter-spacing: 1px;
  background-color: #101010;
  color: white;
  height: 100%; }
  .universe h1 {
    font-weight: 300;
    font-size: 2.5em;
    text-align: center; }
  .universe a {
    color: #F2C200; }
  .universe .block {
    min-height: 100vh; }
    .universe .block .row {
      width: calc(100% - 40px);
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: center;
      justify-content: center; }
      .universe .block .row.no-collapse {
        flex-direction: row; }
      @media (min-width: 992px) {
        .universe .block .row {
          width: 70%;
          flex-direction: row; } }
    .universe .block .title {
      width: 100%; }
      .universe .block .title h2 {
        width: 100%;
        text-align: center;
        font-weight: 400;
        margin-bottom: 2px; }
      .universe .block .title .underline {
        height: 1px;
        width: 40px;
        margin: auto;
        background-color: #F2C200;
        margin-bottom: 40px; }
    .universe .block .half {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      margin-bottom: 40px; }
      @media (min-width: 992px) {
        .universe .block .half {
          width: 50%;
          margin-bottom: 0px; } }
    .universe .block .third {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      margin-bottom: 40px; }
      @media (min-width: 992px) {
        .universe .block .third {
          width: 33%;
          margin-bottom: 0px; } }
      .universe .block .third.nomargin {
        margin-bottom: 0; }
  .universe .footer {
    height: 50px;
    line-height: 50px;
    background-color: black;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase; }
    .universe .footer p {
      margin: 0;
      transition: all 250ms;
      cursor: pointer; }
      .universe .footer p:hover {
        color: #F2C200; }
